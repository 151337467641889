import React from "react";
import "./FeatureOffering.scss";

function FeatureOffering() {
  const sections = [
    {
      heading: "Introducing DOER-R: The Essential Leadership Framework",
      subHeading1: "At leadership.NET, we’ve boiled down the essentials of leadership into the DOER-R framework, which informs all of our offerings. LeaderBridge brings this framework to life, guiding you through a structured process for tackling real-world leadership challenges:",
      subHeading2:
        "Begin with anonymous practice sessions to engage with peers in a safe environment. As trust builds, invite peers into your inner circle, progressing to audio or video conversations.",
      actions: [
        {
          action: "Define",
          description: "Identify the challenge."
        },
        {
          action: "Own",
          description: "Take responsibility for leading the solution."
        },
        {
          action: "Enlist",
          description: "Collaborate and gain support from others."
        },
        {
          action: "Resolve",
          description: "Take action and solve the issue."
        },
        {
          action: "Reinforce",
          description: "Reflect and solidify your success."
        }
      ],
      image: "/assets/images/connection.png",
      alt: "Verification and Anonymity",
    },
    // {
    //   heading: "Features",
    //   actions: [
    //     { action: 'No AI, No Algorithms', description: 'LeaderBridge ensures full transparency and user control, built on the principles of the leadership.NET network.' },
    //     { action: 'Practice on Your Terms', description: 'Engage in leadership practice at your own pace, whether for extended or brief sessions.' },
    //     { action: 'Human Connection', description: 'Receive genuine feedback from peers, avoiding AI-generated suggestions.' },
    //     { action: 'Inner Circle', description: 'Transition from anonymous practice to trusted audio and video conversations with your inner circle.' }
    //   ],
    //   image: "/assets/images/connecting-people.jpg",
    //   alt: "Verification and Anonymity",
    // },
    // {
    //   heading: "Higher-Touch Offerings: Deeper Dive Executive Assessments and Expert Executive Coaching ",
    //   subHeading1: "In addition to peer-driven practice on LeaderBridge, leadership.NET offers professional executive assessments and expert executive coaching services for those seeking deeper leadership development. These services complement your peer-driven practice, providing tailored, expert guidance.",
   
    //   image: "/assets/images/connecting-people.jpg",
    //   alt: "Verification and Anonymity",
    // },
  ];

  return (
    <section >
      {sections.map((section, i) => {
        return (
          <div  className="section-wrapper" key={i}>
            
            <div> 
              {/* <img className="image" alt={section.alt} src={section.image} /> */}
            </div>
            <div>
              <p className="heading">{section.heading}</p>
              <span> {section?.subHeading1} </span>
              <span>{section?.subHeading2}</span>
              <ul className="unordered-list">
                { Array.isArray(section.actions) &&  section.actions.map((action, i) => (
                  <li key={i}>
                    <span className="action-heading">{action.action}: </span>
                   <span className="action-desc">{action.description}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        );
      })}
      <p>This framework encourages daily leadership action, helping you build lasting habits with continuous feedback from your peers.
</p>
    </section>
  );
}

export default FeatureOffering;
