import React, { useState } from "react";
import "./Hero.css";
import Scroller from "./Scroller/Scroller";
// import Ln_logo from "./Ln_logo/Ln_logo";
import LN_Logo from "../../src/images/ln_logo.jpg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FeatureOffering from "./FeatureOffering/FeatureOffering";
import LeadershipProcess from "./LeadershipProcess/LeaderShipProcess";
import AnonymitySection from "./AnonamitySection/AnonimitySection";
import DoerRSection from "./DoerRSection/DoerRSection";
import FeedbackSection from "./FeedbackSection/FeedbackSection";
import LeadershipJourneySection from "./LeadershipJourneySection/LeadershipJourneySection";
import LeadershipExerciseCTA from "./LeadershipExerciseCTA/LeadershipExerciseCTA";
import NextStepsSection from "./NextStepsSection/NextStepsSection";
import WebinarSignUpSection from "./WebinarSignUpSection/WebinarSignUpSection";
import HeroSection from "./HeroSection/HeroSection";
import SnapshotsSection from "./SnapshotsSection/SnapshotsSection";
import WhyLeaderBridge from "./WhyLeaderBridge/WhyLeaderBridge";
// import SubHeader from "./subHeader/subHeader";

const Hero = () => {
  const [open, setOpen] = useState(true);
  const handleRedirect = (url) => {
    window.location.href = url;
  };

  const remover = () => {
    setOpen((prev) => !prev);
  };

  const history = useHistory();

  return (
    <>
      {/* <SubHeader /> */}
      <div id="home">
        {open && (
          <div className="notification-bar">
            <div className="notification-content">
              <div
                className="for-teams"
                onClick={() => handleRedirect("https://leaderbridge.com/")}
              >
                FOR PEERS
              </div>
              <div
                className="logo"
                onClick={() => handleRedirect("https://leaderbridge.com/")}
              >
                <img src="/assets/images/LeaderBridge-logo.png" alt="Logo" />
                <span style={{ fontWeight: "800" }}>:</span>
                {/* <span className="principles-us">PrinciplesUs</span> */}
              </div>
              {/* <div className="notification-text">
                Grow skills together using our anonymous peer practice portal
              </div> */}

              <div className="notification-text">
                Peers practicing leadership.
              </div>
              <div
                className="learn-more"
                onClick={() => handleRedirect("https://leaderbridge.com/about")}
              >
                About LeaderBridge
                {/* <span>&#8250;</span> */}
              </div>
              {/* <button className="close-btn" onClick={() => setOpen(false)}>
                &times;
              </button> */}
            </div>
            {/* <div className="notification-text">
                Grow skills together using our anonymous peer practice portal
              </div> */}
          </div>
        )}

        {/* <div className="principles-you">
          <div className="new-bg-images">
            <main className="principles-main">
              <div className="left-content">
                <div className="let-ln-logo-container">
                  <div className="brand-logo">
                    <img src={LN_Logo} alt="Logo" />
                    <div className="leader-ship">
                      <span className="leader">leadership.</span>
                      <span className="ship">NET</span>
                      <span className="trademark">TM</span>

                      <span className="network">
                        Whether you’re an emerging leader or a seasoned
                        executive, leadership.NET offers leadership resources,
                        expert guidance, free webinars, and structured
                        opportunities to practice leadership in meaningful ways.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="let-ln-logo-container"></div>
                <div className="change-the-text">
                  <span class="doer-font">Practice with Peers.</span> <br />
                  <span class="doer-font" sty>
                    Practice with a PhD expert.
                  </span>{" "}
                  <br />
                  <span className="doer-font">Help others. Help yourself.</span>
                  <br />
                  <button
                    className="assessment-button"
                    onClick={() =>
                      handleRedirect(
                        "https://leaderbridge.com/assessmentRanking?data=ranking1"
                      )
                    }
                  >
                    <span>
                      Take the Free Leadership Context Profile (10 mins)
                    </span>
                  </button>
                  <div className="profile-link">
                    <div className="create-profile-line">
                      <span
                        className="link-tag"
                        onClick={() => history.push("/signup")}
                      >
                        CREATE A PROFILE
                      </span>
                      <span style={{ marginLeft: "5px" }}>
                        {" "}
                        TO SAVE YOUR PROGRESS.
                      </span>
                    </div>
                    <div className="login-line">
                      <span>Already have an account? </span>
                      <span
                        className="link-tag"
                        onClick={() => history.push("/login")}
                      >
                        {" "}
                        [Log in]
                      </span>
                    </div>
                  </div>
                </div>
                <div class="export-assessment-box">
                  <span class="export-text">
                    Expert Assessment and Guidance <br /> Peer-to-Peer Practice
                  </span>
                </div>
              </div>
              <div className="right-graphic">
                <p className="heading">
                  A network of people who practice leadership
                </p>
                <img src="/assets/images/new-pepops.png" alt="Logo" />
                Replace with actual image path or graphic component
                <img src="/path-to-graphic.png" alt="Right Graphic" />
              </div>
            </main>

            <img className="bottom-image" src="/assets/images/three.png" />
          </div>
          <div className="both-box-container">
            <span className="Expert-Assessment">
              Expert Assessment and Guidance Peer-to-peer Practice
            </span>

            <div className="box-main-container"> </div>
            <div className="box-main-container">
              {" "}
              <div className="box-container">
                <div className="box">
                  <ul className="second-box">
                    {
                      leadershipSteps.map(context => (
                        <li>{context}</li>
                      ))
                    }
                  </ul>
                  <blockquote className="second-box">
                    "Leadership is fundamental, and all of us are required to
                    lead at some point. At Leadership.NET, we're dedicated to
                    helping you practice your leadership so that when you are
                    called to lead, you will be ready."
                  </blockquote>

                  <h6 className="John-Behr">
                    — John Behr, PhD, LeaderBridge Co-Visionary
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="feature-offering">
          <div>afa</div>
          <div>
            <p> Peer-to-Peer Practice on LeaderBridge </p>
          </div>
        </div> */}
        {/* <FeatureOffering /> */}
        {/* <Scroller />
         */}

        {/* <div>
          <h1 className="how">How It Works:</h1>

          <ol>
            {leadershipSteps.map(title => (
              <li className="first-title">
                {title.action}: 
                <span className="second-title">
                  {title.description}
                </span>
              </li>
            ))}
          </ol>
        </div> */}
        <div className="new-bg-images">
          <main className="principles-main">
            <div className="left-content">
              <div className="let-ln-logo-container">
                <div className="brand-logo">
                  <img src={LN_Logo} alt="Logo" />
                  <div className="leader-ship">
                    <span className="leader">leadership.</span>
                    <span className="ship">NET</span>
                    <span className="network">
                       : A network of people who practice leadership
                    </span>
                  </div>
                </div>
              </div>
              <div className="let-ln-logo-container">
                {/* <Ln_logo /> */}
              </div>{" "}
              <div className="change-the-text">
                {/* <span class="doer-font">Practice with Peers.</span> <br />
                  <span class="doer-font" sty>
                    Practice with a PhD expert.
                  </span>{" "}
                  <br />
                  <span className="doer-font">Help others. Help yourself.</span> */}
                <br />
                <button
                  className="assessment-button"
                  onClick={() =>
                    handleRedirect(
                      "https://leaderbridge.com/assessmentRanking?data=ranking1"
                    )
                  }
                >
                  <span>Start the DOER-R <span id="copy"> &copy; </span>Context Exercise</span>
                </button>
                <div className="profile-link">
                  <div className="create-profile-line">
                    <span
                      className="link-tag"
                      onClick={() =>
                        window.open("https://leaderbridge.com/signup")
                      }
                    >
                      CREATE A PROFILE
                    </span>
                    <span style={{ marginLeft: "5px" }}>
                      {" "}
                      TO SAVE YOUR PROGRESS.
                    </span>
                  </div>
                  <div className="login-line">
                    <span>ALREADY HAVE AN ACCOUNT? </span>{" "}
                    <span
                      className="link-tag"
                      onClick={() => window.open("https://leaderbridge.com")}
                    >
                      {" "}
                      LOG IN
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-graphic">
              {/* <p className="heading">
                  A network of people who practice leadership
                </p> */}
              <img src="/assets/images/new-pepops.png" alt="Logo" />
              {/* Replace with actual image path or graphic component */}
              {/* <img src="/path-to-graphic.png" alt="Right Graphic" /> */}
            </div>
          </main>

          {/* <img className="bottom-image" src="/assets/images/three.png" /> */}
        </div>
        <HeroSection />
        <DoerRSection />
        <WhyLeaderBridge />

        <FeedbackSection />
        <SnapshotsSection />
        <LeadershipJourneySection />
        <LeadershipProcess />
        <AnonymitySection />
        <LeadershipExerciseCTA />
        <NextStepsSection />
        {/* <WebinarSignUpSection /> */}
        <section id="page1">
          <div className="page-container">
            <div className="page-row">
              <div className="page-box quote-box">
                <div className="quote">
                  <h2>
                    <span>“</span>Leadership is an experience everyone can
                    share, not just those at the top.<span>”</span>
                  </h2>
                  <h4>— John Behr, PhD, Co-Visionary of leadership.NET</h4>
                  {/* <p>Creator of LeaderBridge</p> */}
                </div>
              </div>
              {/* <div className="page-box service-box">
                <div className="services">
                  <h1>Our Services</h1>
                  <div className="service">
                    <h3>HI-SD</h3>
                    <p>
                      Our "High Impact-Short Duration" leadership accelerator
                      enhances focus, fosters collaboration, and expedites the
                      resolution of crucial issues.
                    </p>
                  </div>
                  <div className="service">
                    <h3>EXECUTIVE ASSESSMENT</h3>
                    <p>
                      Interviewing, testing, and expert analysis are applied to
                      produce a customized, accurate, and highly reliable report
                      of key developmental opportunities.
                    </p>
                  </div>
                  <div className="service">
                    <h3>EXECUTIVE COACHING</h3>
                    <p>
                      Tailored to executives. Typically three to six months.
                      Higher level of support and guidance. Often begins with
                      Executive Assessment.
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Hero;
