import React from 'react';
import './FeedbackSection.scss';

const FeedbackSection = () => {
  return (
    <section className="feedback-section">
      <div className="feedback-content">
        <h1>Your Feedback Helped Shape LeaderBridge</h1>
        <p>
          Based on invaluable feedback from our early users, we’ve made LeaderBridge more intuitive—providing the right balance of structure and flexibility. You’ll now experience features like ongoing peer feedback, a robust verification process to maintain anonymity, and the assurance that no personal, geographical, or company information is shared.
        </p>
      </div>
      <div className="features">
        <div className="feature">
          <i className="fas fa-comments icon"></i>
          <h3>Ongoing Peer Feedback</h3>
          <p>Receive continuous feedback from peers to help you grow in a judgment-free environment.</p>
        </div>
        <div className="feature">
          <i className="fas fa-user-secret icon"></i>
          <h3>Complete Anonymity</h3>
          <p>No personal, location, or company details are shared, ensuring unbiased, authentic feedback.</p>
        </div>
        <div className="feature">
          <i className="fas fa-shield-alt icon"></i>
          <h3>Robust Verification</h3>
          <p>Our process ensures that all interactions remain anonymous, allowing for honest feedback.</p>
        </div>
      </div>
    </section>
  );
};

export default FeedbackSection;
