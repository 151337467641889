import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// React imports end...

// Dev imports start...
import Hero from "../../components/Hero";
import PerformanceCarousel from "../../components/PerformanceCarousel";
// import WebinarInvitationLandingPage from "../components/Webinar/WebinarInvitationLandingPage";
import WebinarList from "../../components/Webinar/WebinarList/WebinarList";

import { setAllWebinarsHandler } from "../../Pages/Activities/Activities";
import { DOERRCopyright } from "../../utils/brandNames";
import { navigation } from "../../utils/frontendNavigation/frontendNavigation";
// Dev imports end...

// Css imports start...
//import "./LandingPage.css";
import "./applied.css";
// Css imports end...

const Applied = () => {
  const history = useHistory();

  // Static data start...
  // State variables init state start...
  const allWebinarsInitState = {
    upcoming: [],
    archived: [],
  };
  // State variables init state start...
  // Static data end...

  // State variables...
  const [allWebinars, setAllWebinars] = useState(allWebinarsInitState);
  // State variables...

  // Effects start...
  useEffect(() => {
    document.title = "Services";
  }, []);

  useEffect(() => {
    setAllWebinarsHandler(setAllWebinars);
  }, []);
  // Effects end...

  console.log("ALLWEBINARS", allWebinars);

  return (
    <>
      <div class="page-wrapper">
        {/* <div id="ht-preloader">
                <div class="loader clear-loader"> <span>W</span>
                    <span>i</span>
                    <span>n</span>
                    <span>c</span>
                    <span>k</span>
                </div>
            </div> */}

        {/* <Hero /> */}

        <div class="page-content" style={{ marginTop: "13px" }}>
        <div className="leader-solutions">
      <h1 className="mb-5 text-black">
        <span className="font-family-lustria fs-2">Solutions for Leaders</span>
      </h1>
      <section
        className="pt-0 text-center content-section"
      >
        <div>
          <p className="fs-text mb-5">
            While leadership knowledge is common, its consistent application is not.
          </p>

          <p className="fs-text">
            Working 1-on-1 with over 4,000 leaders has taught us that the most effective of them are consistently applying a basic leadership optimization framework. This framework forms the cornerstone of all of our applications. Our pragmatic approach to helping leaders considers theory, and prioritizes practice, ultimately enhancing ROI and affecting sustained improvements.
          </p>
        </div>
      </section>
    </div>
          {/* <WebinarInvitationLandingPage /> */}
          {/* <WebinarList
            webinars={allWebinars?.upcoming}
            title={`Upcoming Webinars`}
            navigateToWebinarDetails={true}
            customComponents={{
              seeMoreBtn: (
                <button
                  style={{
                    marginTop: "2rem",
                  }}
                  onClick={() => {
                    history.push({
                      pathname: `${navigation.activities}`,
                    });
                  }}
                >
                  See More
                </button>
              ),
            }}
          /> */}
          {/* <!--services start--> */}
          {/* <section class="p-0">
            <div class="container">
              <div class="mb-5">
                <h2 class="mb-0 font-w-4 text-center">Services</h2>
              </div>

              <div
                class="d-flex align-items-center justify-content-between services-container"
                style={{
                  gap: "3rem",
                }}
              >
                <div class="services-container-item">
                  <div
                    class="rounded bg-white shadow text-center"
                    style={{
                      padding: "2rem 1rem",
                    }}
                  >
                    <div>
                      <img
                        class="img-fluid"
                        src="/assets/images/services-images/Intake-Planning.jpg"
                        alt=""
                        style={{
                          width: "250px",
                          height: "250px",
                          // objectFit: 'cover'
                        }}
                      />
                    </div>
                    <h5 class="mt-4 mb-3"> HI-SD</h5>
                    <p class="mb-0">
                      Our "High Impact-Short Duration" leadership accelerator
                      enhances focus, fosters collaboration and expedites the
                      resolution of crucial issues.
                    </p>
                  </div>
                </div>
                <div class="services-container-item">
                  <div
                    class="rounded bg-white shadow text-center"
                    style={{
                      padding: "2rem 1rem",
                    }}
                  >
                    <div>
                      <img
                        class="img-fluid"
                        src="/assets/images/services-images/Executive-assessment.jpg"
                        alt=""
                        style={{
                          width: "250px",
                          height: "250px",
                          // objectFit: 'cover'
                        }}
                      />
                    </div>
                    <h5 class="mt-4 mb-3">Executive Assessment</h5>
                    <p class="mb-0">
                      Interviewing, testing and expert analysis are applied to
                      produce a customized, accurate and highly reliable report
                      of key developmental opportunities.
                    </p>
                  </div>
                </div>
                <div class="services-container-item">
                  <div
                    class="rounded bg-white shadow text-center"
                    style={{
                      padding: "2rem 1rem",
                    }}
                  >
                    <div>
                      <img
                        class="img-fluid"
                        src="/assets/images/ap-executive-coaching.avif"
                        alt=""
                        style={{
                          width: "250px",
                          height: "250px",
                          // objectFit: 'cover'
                        }}
                      />
                    </div>
                    <h5 class="mt-4 mb-3">Executive Coaching</h5>
                    <p class="mb-0">
                      Tailored to executives. Typically three to six months.
                      Higher level of support and guidance. Often begins with
                      Executive Assessment.
                    </p>
                  </div>
                </div>

                <div class="services-container-item">
                  <div
                    class="rounded bg-white shadow text-center"
                    style={{
                      padding: "2rem 1rem",
                    }}
                  >
                    <div>
                      <img
                        class="img-fluid"
                        src="/assets/images/3902638.jpg"
                        alt=""
                        style={{
                          width: "250px",
                          height: "250px",
                          // objectFit: 'cover'
                        }}
                      />
                    </div>
                    <h5 class="mt-4 mb-3"> H-1B</h5>
                    <p class="mb-0">
                      A 1-on-1 assessment, feedback and alignment program that
                      bridges cultural gaps, refines communication, and hones
                      team skills, leading to faster integration, improved
                      performance, and reduced turnover.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          {/* <!--services end-->    */}
          {/* <!--adaptive services start--> */}
          {/* <section>
                        <div class="container">
                        <div class="d-flex justify-content-center">
                            <div class="col-lg-6 col-md-5 mb-5">
                            <div>
                                <h2><span class="font-w-4 d-block text-center">Adaptive Services</span></h2>
                                <p class="lead text-center">Two innovative services leveraging Fortune 100 expertise to accelerate
                                the leadership growth of mid-sized companies</p>
                            </div>
                            </div>
                        </div>
                        <div class="d-flex gap-5 justify-content-center">
                            <div class="col-lg-5">
                            <div class="d-flex flex-column align-items-center feature-hover position-relative">
                                <div class="">
                                    <img
                                        src="https://southcentralus1-mediap.svc.ms/transform/thumbnail?provider=spo&inputFormat=png&cs=fFNQTw&docid=https%3A%2F%2Fleaderbridgellc-my.sharepoint.com%3A443%2F_api%2Fv2.0%2Fdrives%2Fb!rERXpEP8B0yJqsEjl68bXYg99z3tsvxEtaJSlhXa95BBmmAqIYvUSoMWjAE8s07l%2Fitems%2F01EUN6ITSS5HHC4ZZWEFH243SKLVMSWGQS%3Fversion%3DPublished&access_token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAvbGVhZGVyYnJpZGdlbGxjLW15LnNoYXJlcG9pbnQuY29tQDNiZmNjNzJiLTFhOTktNDQ0ZS1hZGJkLTUwNmMzOTNlMWRjNyIsImlzcyI6IjAwMDAwMDAzLTAwMDAtMGZmMS1jZTAwLTAwMDAwMDAwMDAwMCIsIm5iZiI6IjE2OTM1NDgwMDAiLCJleHAiOiIxNjkzNTY5NjAwIiwiZW5kcG9pbnR1cmwiOiIyRUJTSEFsZzd2aSt1SFI3TnNxS05jUW0vTnMvRmoxQjRZMlRISitQQ0lvPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTI1IiwiaXNsb29wYmFjayI6IlRydWUiLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIiwic2l0ZWlkIjoiWVRRMU56UTBZV010Wm1NME15MDBZekEzTFRnNVlXRXRZekV5TXprM1lXWXhZalZrIiwibmFtZWlkIjoiMCMuZnxtZW1iZXJzaGlwfHVybiUzYXNwbyUzYWFub24jYjU0YTRhOTQzZjI3NGY0YTc2YjJkMTJiZjAzOGEzZGFmYjA3ZDhjMDUyY2NjNGUzMjBjOTU4NzBhYWZiNThmOSIsIm5paSI6Im1pY3Jvc29mdC5zaGFyZXBvaW50IiwiaXN1c2VyIjoidHJ1ZSIsImNhY2hla2V5IjoiMGguZnxtZW1iZXJzaGlwfHVybiUzYXNwbyUzYWFub24jYjU0YTRhOTQzZjI3NGY0YTc2YjJkMTJiZjAzOGEzZGFmYjA3ZDhjMDUyY2NjNGUzMjBjOTU4NzBhYWZiNThmOSIsInNoYXJpbmdpZCI6IlZGWWRwd2lLbkVPVEdVNytwa3d5ZmciLCJ0dCI6IjAiLCJpcGFkZHIiOiIxMTAuMjI1LjIxNi4yNDYifQ.FoH9JLeepT5QzHh9yyH257CtTi8Uh1EY5jPg8Ujg5sY&cTag=%22c%3A%7B2ECEE952-3667-4F21-AE6E-4A5D592B1A12%7D%2C2%22&encodeFailures=1&width=512&height=512&srcWidth=512&srcHeight=512"
                                        style={{
                                            width: '50px',
                                            height: '50px'
                                        }}
                                    ></img>
                                </div>
                                <h5 class="mt-4 mb-3">HISD COACHING</h5>
                                <p class="mb-0 text-center">High Intensity Short Duration coaching is a concentrated 2-month engagement that draws out the Fortune 100 potential of leaders in growing companies, increasing executives’ performances resulting in greater efficiency, constructive professional communication, and career fulfillment.</p>
                            </div>
                            </div>
                            <div class="col-lg-5">
                            <div class="d-flex flex-column align-items-center feature-hover position-relative">
                                <div class="">
                                    <img
                                        src="https://southcentralus1-mediap.svc.ms/transform/thumbnail?provider=spo&inputFormat=png&cs=fFNQTw&docid=https%3A%2F%2Fleaderbridgellc-my.sharepoint.com%3A443%2F_api%2Fv2.0%2Fdrives%2Fb!rERXpEP8B0yJqsEjl68bXYg99z3tsvxEtaJSlhXa95BBmmAqIYvUSoMWjAE8s07l%2Fitems%2F01EUN6ITUVX324PBSB4VD2TMJCVSPSXBPB%3Fversion%3DPublished&access_token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAvbGVhZGVyYnJpZGdlbGxjLW15LnNoYXJlcG9pbnQuY29tQDNiZmNjNzJiLTFhOTktNDQ0ZS1hZGJkLTUwNmMzOTNlMWRjNyIsImlzcyI6IjAwMDAwMDAzLTAwMDAtMGZmMS1jZTAwLTAwMDAwMDAwMDAwMCIsIm5iZiI6IjE2OTM1NDgwMDAiLCJleHAiOiIxNjkzNTY5NjAwIiwiZW5kcG9pbnR1cmwiOiIyRUJTSEFsZzd2aSt1SFI3TnNxS05jUW0vTnMvRmoxQjRZMlRISitQQ0lvPSIsImVuZHBvaW50dXJsTGVuZ3RoIjoiMTI1IiwiaXNsb29wYmFjayI6IlRydWUiLCJ2ZXIiOiJoYXNoZWRwcm9vZnRva2VuIiwic2l0ZWlkIjoiWVRRMU56UTBZV010Wm1NME15MDBZekEzTFRnNVlXRXRZekV5TXprM1lXWXhZalZrIiwibmFtZWlkIjoiMCMuZnxtZW1iZXJzaGlwfHVybiUzYXNwbyUzYWFub24jYjU0YTRhOTQzZjI3NGY0YTc2YjJkMTJiZjAzOGEzZGFmYjA3ZDhjMDUyY2NjNGUzMjBjOTU4NzBhYWZiNThmOSIsIm5paSI6Im1pY3Jvc29mdC5zaGFyZXBvaW50IiwiaXN1c2VyIjoidHJ1ZSIsImNhY2hla2V5IjoiMGguZnxtZW1iZXJzaGlwfHVybiUzYXNwbyUzYWFub24jYjU0YTRhOTQzZjI3NGY0YTc2YjJkMTJiZjAzOGEzZGFmYjA3ZDhjMDUyY2NjNGUzMjBjOTU4NzBhYWZiNThmOSIsInNoYXJpbmdpZCI6IlZGWWRwd2lLbkVPVEdVNytwa3d5ZmciLCJ0dCI6IjAiLCJpcGFkZHIiOiIxMTAuMjI1LjIxNi4yNDYifQ.FoH9JLeepT5QzHh9yyH257CtTi8Uh1EY5jPg8Ujg5sY&cTag=%22c%3A%7BC7F5BE95-4186-47E5-A9B1-22AC9F2B85E1%7D%2C2%22&encodeFailures=1&width=512&height=512&srcWidth=512&srcHeight=512"
                                        style={{
                                            width: '50px',
                                            height: '50px'
                                        }}
                                    ></img>
                                </div>
                                <h5 class="mt-4 mb-3">STREAMLINE ASSESSEMENT</h5>
                                <p class="mb-0 text-center">Streamlined Assessments save time and accelerating professional growth by focusing on the core human factors most pertinent to the workplace. They provide the data and insights that executives and organizations need to understand themselves and achieve their full potential.</p>
                            </div>
                            </div>
                        </div>
                        </div>
                    </section> */}
          {/* <!--adaptive services end--> */}
          {/* <!--testimonial start--> */}
          {/* <section>
                        <div class="container">
                        <div class="row align-items-center">

                            <div class="mb-5">
                            <h2 class="mb-0 font-w-4 text-center">Testimonials</h2>
                            </div>

                            <div class="col-md-6">
                            
                            <div class="card p-3 p-md-5 border-0 bg-white shadow">
                                <div class="card-body p-0">
                                <i class="las la-quote-left ic-2x text-white bg-primary rounded-circle p-1"></i> 
                                <p class="font-w-5 lead my-3">
                                    If you are trying to break through to further develop your leadership competencies, interpersonal skills and relationships, John will give you an honest and straightforward assessment of "that one thing" for your improvement. Change, and taking the steps are up to you!
                                </p>
                                <i class="las la-quote-right ic-2x text-white bg-primary rounded-circle p-1"></i> 
                                <div class="d-flex align-items-center mt-3">
                                    <div class="ms-3">
                                    <h5 class="text-primary mb-0">Jandrea Leiter</h5>
                                    <small class="text-muted fst-italic">- VP of Marketing Oakbrook Consulting</small>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div class="col-md-6 mt-5 mt-md-0">
                            <div class="card p-3 p-md-5 border-0 bg-light">
                                <div class="card-body p-0">
                                <i class="las la-quote-left ic-2x text-white bg-primary rounded-circle p-1"></i> 
                                <p class="font-w-5 lead my-3">
                                    John was a very effective assessor during my evaluation for a new job. I found him prepared, professional, and an astute interviewer. His assessment skills gave both the applicant and the prospective employer great insight into each other
                                </p>
                                <i class="las la-quote-right ic-2x text-white bg-primary rounded-circle p-1"></i> 
                                <div class="d-flex align-items-center mt-3">
                                    <div class="ms-3">
                                    <h5 class="text-primary mb-0">Hooper Harris</h5>
                                    <small class="text-muted fst-italic">- Aviation Safety and Accident Investigation</small>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <div class="card p-3 p-md-5 border-0 bg-light">
                                <div class="card-body p-0"> 
                                <i class="las la-quote-left ic-2x text-white bg-primary rounded-circle p-1"></i> 
                                <p class="font-w-5 lead my-3">
                                    Through better relationship building and in elevating trust in one another, John’s deep involvement helped my teams accomplish what, at the onset, seemed insurmountable. I’d recommend John as an executive leadership coach or organizational development advisor to anybody or any business valuing improved performance through people.
                                </p>
                                <i class="las la-quote-right ic-2x text-white bg-primary rounded-circle p-1"></i> 
                                <div class="d-flex align-items-center mt-3">
                                    <div class="ms-3">
                                    <h5 class="text-primary mb-0">Randall Hernandez</h5>
                                    <small class="text-muted fst-italic">- President and Founder VestAg, LLC</small>
                                    </div>
                                </div>
                                </div>
                            </div>

                            </div>
                        </div>
                        </div>
                    </section> */}
          {/* <!--testimonial end-->        */}
          {/* <!--performance start--> */}
          <section>
            <div class="container">
              <div class="row justify-content-center text-center">
                <div class="col-lg-8">
                  <div class="mb-3">
                    <h2>
                      <span class="font-w-4 d-block">
                        Working with leaders for more than 30 years
                      </span>
                    </h2>
                    {/* <!-- <p class="lead mb-0">We use the latest technologies it voluptatem accusantium doloremque laudantium.</p> --> */}
                  </div>
                </div>
              </div>
              <div class="row align-items-center text-center">
                <PerformanceCarousel />

                {/* <div class="col-12 col-sm-6 col-lg-3">
                            <div class="counter">
                                <div class="counter-desc text-dark"> <span class="count-number h2" data-to="28" data-speed="2000">28</span>
                                <span class="h2">+</span> 
                                <h6 class="text-muted mb-0">Project Completed</h6>
                                </div>
                            </div>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-3 mt-4 mt-sm-0">
                            <div class="counter">
                                <div class="counter-desc text-dark"> <span class="count-number h2" data-to="50" data-speed="2000">50</span>
                                <span class="h2">+</span> 
                                <h6 class="text-muted mb-0">Support Member</h6>
                                </div>
                            </div>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-3 mt-4 mt-lg-0">
                            <div class="counter">
                                <div class="counter-desc text-dark"> <span class="count-number h2" data-to="99" data-speed="2000">99</span>
                                <span class="h2">+</span> 
                                <h6 class="text-muted mb-0">People Love Us</h6>
                                </div>
                            </div>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-3 mt-4 mt-lg-0">
                            <div class="counter">
                                <div class="counter-desc text-dark"> <span class="count-number h2" data-to="120" data-speed="2000">120</span>
                                <span class="h2">+</span> 
                                <h6 class="text-muted mb-0">Happy Customers</h6>
                                </div>
                            </div>
                            </div> */}
              </div>
            </div>
          </section>
          {/* <!--performance end--> */}
          {/* <!--newsletter start--> */}
          {/* <section className="pb-0">
                        <div class="">
                        <div class="row">
                            <div class="col">
                            <div class="bg-light bg-pos-l py-6 px-4 px-lg-6 text-center rounded" 
                                style={{
                                    backgroundImage: `url("/assets/images/bg/02.png")`,
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover'
                                }}
                            >
                                <div class="mb-2">
                                    <h2><span class="font-w-4 d-block"></span> Get In Touch</h2>
                                </div>

                                <div class="mb-5">
                                <p>Have A Question?</p>
                                </div>

                                <div class="elementor-button-wrapper mb-5">
                                <a href="http://54.164.68.122/contact/" className="elementor-button-link elementor-button elementor-size-sm btn-dark" role="button">
                                    Contact Us
                                </a>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </section>  */}
          {/* <!--newsletter end--> */}
        </div>
      </div>
    </>
  );
};

export default Applied;
