import React from "react";
import "./AnonymitySection.scss";

const AnonymitySection = () => {
  return (
    <div className="anonymity-section">
      <div className="container">
        <h2>Anonymity, No AI, and Accountability</h2>
        <p className="intro-text">
          At <span className="highlight">LeaderBridge</span>, we believe in real
          interactions with real people, not algorithms or shortcuts. Our
          platform is free from AI and personal data, focusing on genuine
          human connection and peer-driven feedback.
        </p>

        <div className="features">
          {/* Feature 1 */}
          <div className="feature-card">
            <i className="fas fa-robot icon"></i>
            <h3>No AI Interference</h3>
            <p>
              LeaderBridge prepares you for real-world leadership by helping you
              rely on your skills and the support of other leaders. No AI making
              decisions ensures honest feedback, and sustainable learning.
            </p>
          </div>

          {/* Feature 2 */}
          <div className="feature-card">
            <i className="fas fa-shield-alt icon"></i>
            <h3>Full Accountability</h3>
            <p>
              To maintain integrity and trust, all participants are verified before engaging with peers, ensuring a safe,
              accountable environment for growth.
            </p>
          </div>

          {/* Feature 3 */}
          <div className="feature-card">
            <i className="fas fa-users icon"></i>
            <h3>Human-Driven Connections</h3>
            <p>
              With no personal data shared, LeaderBridge focuses on unbiased,
              honest peer-to-peer feedback to help you grow as a leader.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnonymitySection;
