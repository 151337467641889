import React from 'react';
import './WhyLeaderBridge.scss';

const WhyLeaderBridge = () => {
  return (
    <section className="why-leaderbridge">
      <div className="wrapper">
        <div className="info-section">
          <h2>Why LeaderBridge?</h2>
          <p className="intro">A Smart Investment in Your Leadership Growth</p>
          <p className="description">
            Traditional leadership programs, seminars, and workshops can cost hundreds or even thousands of dollars, often for a single session or event. LeaderBridge offers something different: a peer-driven, practice-based approach to leadership development, for a fraction of the cost.
          </p>
        </div>

        <div className="comparison-section">
          <div className="price-comparison">
            <div className="price-item">
              <span className="label">Leadership Seminars</span>
              <span className="price">$500+ for a one-time experience</span>
            </div>
            <div className="price-item">
              <span className="label">Executive Coaching</span>
              <span className="price">$300-$500 per session</span>
            </div>
            <div className="price-item">
              <span className="label">Workshops</span>
              <span className="price">$1,000+ for limited interaction</span>
            </div>
            <div className="price-item leaderbridge">
              <span className="label">LeaderBridge</span>
              <span className="price">$396/year for unlimited leadership practice, peer feedback, and a 45-minute personal calibration session for early adopters</span>
            </div>
          </div>

          <p className="conclusion">
            For those serious about rapidly growing their leadership skills, especially new and emerging leaders, LeaderBridge provides unmatched value with regular, focused practice and real-time peer collaboration, all in a supportive, anonymous environment.
          </p>
        </div>
      </div>
    </section>
  );
};

export default WhyLeaderBridge;
