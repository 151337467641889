import React from "react";
import "./LeadershipProcess.scss";
import { NavLink } from "react-router-dom";

const LeadershipProcess = () => {
  const steps = [
    {
      link: "https://leaderbridge.com/assessmentRanking?data=ranking1",
      title: "1. Complete Your DOER-R Context Exercise",
      description:
        "",
      buttonText: "Start the DOER-R Context Exercise",
    },
    {
      link: "https://leaderbridge.com/assessmentRanking?data=ranking1",
      title: "2. View Your DOER-R Context Snapshot",
      description:
        "After completing the exercise, view a one-page summary to help you better understand your leadership.",
      buttonText: "View Snapshot",
    },
    {
      link: "https://leaderbridge.com/assessmentRanking?data=ranking1",
      title: "3. Set Up Your First LeaderBridge Practice",
      description:
        "Leverage your DOER-R Context Snapshot to initiate your first leadership practice session and match with the best practice partners.",
      buttonText: "Start Practice",
    },
    {
      navLink: "/Feature_Pricing",
      title: "4. Pricing",
      description:
        "Early adopters are required to subscribe for a full year and to show our appreciation we will give you a free 45-minute leadership consultation.",
      buttonText: "Choose My Plan",
    },
    {
      link: "https://leaderbridge.com/assessmentRanking?data=ranking1",
      title: "5. Complete Verification and Payment",
      description:
        "All new members adopters undergo a quick verification process before subscribing. This ensures a committed and engaged group of leaders ready to join the platform together and fully embrace the peer-driven leadership experience.",
      buttonText: "Complete Verification",
    },
    {
      link: "https://leaderbridge.com/assessmentRanking?data=ranking1",
      title: "6. Practice Starts Soon!",
      description:
        "New subscribers will wait for a week or so until we've gathered a critical number of committed leaders for the new consort.",
      buttonText: "Start Practicing",
    },
  ];

  return (
    <section className="leadership-process">
      <div className="content-wrapper">
        {steps.map((step, index) => (
          <div className="step-card" key={index}>
            <div className="step-header">
              <h3>{step.title}</h3>
            </div>
            <div className="step-body">
              <p>{step.description}</p>
            </div>
            <div className="step-footer">
              {
                step.navLink ?   <NavLink className="start-button" to="/Feature_Pricing" >
                 Pricing
              </NavLink> :  <a href={step.link} className="start-button">
                {step.buttonText}
              </a>
              }
             

            
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default LeadershipProcess;
