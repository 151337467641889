import React from "react";
import "./LeadershipJourneySection.scss";

const LeadershipJourneySection = () => {
  return (
    <section className="leadership-journey-section">
      <div className="content-wrapper">
        <div className="header">
          <h2>Explore Your Leadership Journey</h2>
          <p className="subheading">
            Engage with unique opportunities to enhance your leadership skills.
          </p>
        </div>
        <div className="cards-container">
          <div className="card">
            <i className="fas fa-pencil-alt icon"></i>
            <h3>Complete the <br></br><span>DOER-R
              </span> Context Exercise</h3>
            <p>
              This simple exercise provides a snapshot of how you show up in
              different parts of the leadership experience.
            </p>
          </div>
          <div className="card">
            <i className="fas fa-users icon"></i>
            <h3>Discover LeaderBridge</h3>
            <p>
              Peer-driven leadership practice designed to help new and emerging leaders
              grow with support from other leaders facing real challenges.
            </p>
          </div>
          <div className="card">
            <i className="fas fa-calendar-alt icon"></i>
            <h3>Annual Plan for LeaderBridge</h3>
            <p>
              Early adopters subscribe to the LeaderBridge annual plan and will
              receive a 45-minute leadership calibration session with the
              LeaderBridge Co-Visionary. Subscribers will wait for a week or so
              until we've gathered a critical number of committed leaders. Once
              we reach this milestone, you will be part of the first cohort to
              join the platform together, creating an instant network.
            </p>
          </div>
          <div className="card">
            <i className="fas fa-money-bill-wave icon"></i>
            <h3>14-Day Money-Back Guarantee</h3>
            <p>
              Try LeaderBridge risk-free. If you're not satisfied within 14 days
              of our official launch, get a full refund—no questions asked. This
              ensures you have peace of mind as you join a community of fully
              committed leaders.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LeadershipJourneySection;
