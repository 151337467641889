import React from 'react';
import './about.css';

const AboutUs = () => {
  return (
    <section className="about-us">
      <div className="content-wrapper">
        <h1>About Us</h1>
        <div className="mission-section">
          <h2>Our Mission</h2>
          <p className="mission-text">
            Leaders helping leaders solve real leadership challenges. We focus on practical solutions and are designed with leaders in mind.
          </p>
        </div>

        <div className="history-section">
          <p>
            For nearly 25 years, leadership.NET has worked with thousands of executives across over 100 companies in the U.S., Europe, India, Japan, and Southeast Asia. Our approach has always emphasized peer-to-peer learning and leadership development.
          </p>
          <p>
            In 2020, we launched LeaderBridge, a SaaS platform created to connect leaders with peers for knowledge sharing and mutual support. LeaderBridge grew out of our success in matching clients with peers of similar backgrounds, allowing them to enhance their leadership skills through collaboration and shared experience.
          </p>
        </div>

        <div className="footer">
          <p>© 2024 leadership.NET. All rights reserved.</p>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
