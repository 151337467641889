// React imports start...
import { useRef, useState, useEffect } from "react";
import { useHistory, NavLink } from "react-router-dom";
// React imports end...

// 3rd party packages start...
import "../../fonts/Rockwell/ROCK.TTF";
// 3rd party packages end...

// Dev imports start...
import Hero from "../../components/Hero";
import Platforms from "../../components/Platforms/Platforms";
import Webinar from "../../components/Webinar/Activities";
// import WebinarInvitationLandingPage from "../../components/Webinar/WebinarInvitationLandingPage";
import WebinarList from "../../components/Webinar/WebinarList/WebinarList";

import { setAllWebinarsHandler } from "../../Pages/Activities/Activities";
import {
  leadership,
  leaderbridge,
  appliedPsychologists,
  leaderbridgeLink,
  appliedPsychologistsLink,
  spaceas,
  spaceasLink,
} from "../../utils/brandNames";
import { fetchAllWebinarsHandler } from "../../utils/Api/ApiCalls/webinar";
import { navigation } from "../../utils/frontendNavigation/frontendNavigation";
// Dev imports end...

import "./LandingPage.css";
import Scroller from "../../components/Scroller/Scroller";
import Journey from "../../components/Journey/Journey";
import { ApiGet } from "../../utils/Api/ApiData";
import Auth from "../../utils/auth";

const LandingPage = () => {
  const history = useHistory();

  // Refs start
  const webinarFeaturesRef = useRef();
  // Refs end

  // Static data start...
  // State variables init state start...
  const allWebinarsInitState = {
    upcoming: [],
    archived: [],
  };
  // State variables init state start...
  // Static data end...

  // State variables...
  const [allWebinars, setAllWebinars] = useState(allWebinarsInitState);
  // State variables...

  // Effects start...
  useEffect(() => {
    document.title = "leadership.NET | Home";
  }, []);

  useEffect(() => {
    setAllWebinarsHandler(setAllWebinars);
  }, []);
  // Effects end...

  // const getLoginToken = (token) => {
  //   ApiGet(`user/get-token-login?token=${token}`)
  //     .then((res) => {
  //       Auth.setAuthToken(res?.data?.payload.tokenLogin);
  //       history.push({
  //         pathname: "/dashboard",
  //       });
  //       window.location.reload();
  //     })
  //     .catch((err) => console.log("get-token-login", err));
  // };

  // useEffect(() => {
  //   let token =
  //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyIkX18iOnsic3RyaWN0TW9kZSI6dHJ1ZSwiaW5zZXJ0aW5nIjp0cnVlLCJnZXR0ZXJzIjp7fSwiX2lkIjoiNjZmM2JmOGRiNTdjMzA2MGQ0M2ZmMDUxIiwid2FzUG9wdWxhdGVkIjpmYWxzZSwiYWN0aXZlUGF0aHMiOnsicGF0aHMiOnsic3RyaXBlUGF5bWVudElkIjoicmVxdWlyZSIsInBsYW5JZCI6InJlcXVpcmUiLCJ1c2VySWQiOiJyZXF1aXJlIn0sInN0YXRlcyI6eyJpZ25vcmUiOnt9LCJkZWZhdWx0Ijp7fSwiaW5pdCI6e30sIm1vZGlmeSI6e30sInJlcXVpcmUiOnsic3RyaXBlUGF5bWVudElkIjp0cnVlLCJwbGFuSWQiOnRydWUsInVzZXJJZCI6dHJ1ZX19LCJzdGF0ZU5hbWVzIjpbInJlcXVpcmUiLCJtb2RpZnkiLCJpbml0IiwiZGVmYXVsdCIsImlnbm9yZSJdfSwicGF0aHNUb1Njb3BlcyI6e30sImNhY2hlZFJlcXVpcmVkIjp7fSwic2Vzc2lvbiI6bnVsbCwiJHNldENhbGxlZCI6e30sImVtaXR0ZXIiOnsiX2V2ZW50cyI6e30sIl9ldmVudHNDb3VudCI6MCwiX21heExpc3RlbmVycyI6MH0sIiRvcHRpb25zIjp7ImRlZmF1bHRzIjp0cnVlfSwidmFsaWRhdGluZyI6bnVsbCwiYmFja3VwIjp7ImFjdGl2ZVBhdGhzIjp7Im1vZGlmeSI6eyJ1c2VySWQiOnRydWUsInBsYW5JZCI6dHJ1ZSwic3RyaXBlUGF5bWVudElkIjp0cnVlfSwiZGVmYXVsdCI6eyJfaWQiOnRydWUsInB1cmNoYXNlRGF0ZSI6dHJ1ZX19fSwic2F2ZWRTdGF0ZSI6e319LCJpc05ldyI6ZmFsc2UsIiRsb2NhbHMiOnt9LCIkb3AiOm51bGwsIl9kb2MiOnsiX2lkIjoiNjZmM2JmOGRiNTdjMzA2MGQ0M2ZmMDUxIiwidXNlcklkIjoiNjZmM2FkMjc2MzJhMDQxNTMwYjIwZDQ3IiwicGxhbklkIjoiNjY5MGY2OWZhZjRkOTUzNDQwMzIwZGZkIiwic3RyaXBlUGF5bWVudElkIjoicGlfM1EycHVRQkN4TkhCa1FRZDBHR1ZWY3RCX3NlY3JldF82N1cwRTNxTXNST2JoVXJreTFwR3FhcHJyIiwicHVyY2hhc2VEYXRlIjoiMjAyNC0wOS0yNVQwNzo0NToxNy43NjlaIiwiX192IjowfSwiaWF0IjoxNzI3MjUwMzE3LCJleHAiOjE3MjcyNTM5MTd9.scRGQzziYP-ZVUrdLDS032Gyj_ldsSkzuAezBg5ejTE";
  //   getLoginToken(token);
  // });

  return (
    <>
      <div class="page-wrapper">
        <Hero />
        <Journey />

        <div class="page-content">
          {/* <section className="">
            <div class="container">
              <div class="row justify-content-center text-center">
                <div class="col-lg-8">
                  <div class="">
                    <p class="">
                      <span class="fs-3 font-w-4 d-block">
                        {leadership} is a concentrated suite of software and
                        services that helps leaders solve problems.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          {/* <Webinar
                        webinarFeaturesRef={webinarFeaturesRef}
                    /> */}

          {/* <WebinarInvitationLandingPage /> */}
          {/* <WebinarList
            webinars={allWebinars?.upcoming}
            title={`Upcoming Webinars`}
            navigateToWebinarDetails={true}
            customComponents={{
              seeMoreBtn: (
                <button
                  style={{
                    marginTop: "2rem",
                  }}
                  onClick={() => {
                    history.push({
                      pathname: `${navigation.activities}`,
                    });
                  }}
                >
                  See More
                </button>
              ),
            }}
          /> */}

          {/* <Webinar 
                        showFeatures={true}
                    /> */}

          {/* 
                    <Platforms
                        backgroundColor={`rgba(30, 29, 45, 0.9)`}
                        heading={`Our Network`}
                        textColor={`text-light`}
                    /> */}

          {/* <!--newsletter end--> */}
        </div>
      </div>
    </>
  );
};

export default LandingPage;
