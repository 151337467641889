import React from "react";
import "./NextStepsSection.scss";
import { NavLink } from "react-router-dom";

const NextStepsSection = () => {
  return (
    <div className="next-steps-section">
      <div className="container">
        <h2>Next Steps</h2>
        <ul className="steps-list">
          <li>
            <i className="fas fa-play-circle icon"></i>
            <span className="step-description">
              Complete the DOER-R Context Exercise.
            </span>
            <button
              onClick={() =>
                window.open(
                  "https://leaderbridge.com/assessmentRanking?data=ranking1"
                )
              }
              className="cta-button"
            >
              Start the DOER-R Context Exercise
            </button>
          </li>

          <li>
            <i className="fas fa-check-circle icon"></i>
            <span className="step-description">
              Undergo verification, and subscribe to the LeaderBridge annual
              plan.
            </span>
            <NavLink to={"/Feature_Pricing"} className="cta-button">
              Subscribe
            </NavLink>
          </li>
          <li>
            <i className="fas fa-rocket icon"></i>
            <span className="step-description">
              Once we reach a critical number of subscribers, we will notify
              you, and you'll join the platform with a fully committed network of
              peers.
            </span>
          </li>
          <li>
            <i className="fas fa-users icon"></i>
            <span className="step-description">
              Together, we'll create a powerful leadership practice environment
              from day one.
            </span>
            <button
              onClick={() => window.open("https://leaderbridge.com/signup")}
              className="cta-button"
            >
              Register Now
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NextStepsSection;
