import React from "react";
import "./LeadershipExerciseCTA.scss";

const LeadershipExerciseCTA = () => {
  return (
    <div className="leadership-exercise-cta">
      <div className="container">
        <h2>Free DOER-R Context Exercise</h2>
        <p>
          Take 10 minutes to reflect on your leadership experiences and create a snapshot that will guide your learning calibration session and help you prepare for your journey as an early adopter.
        </p>
        <button onClick={() => window.open("https://leaderbridge.com/assessmentRanking?data=ranking1")} className="cta-button">📝 Start the DOER-R Context Exercise</button>
      </div>
    </div>
  );
};

export default LeadershipExerciseCTA;
