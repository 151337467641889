import React from 'react';
import './HeroSection.scss';

const HeroSection = () => {
  return (
    <section className="hero-section">
      <div className="hero-content">
        <h1>Welcome to <span>leadership.NET</span></h1>
        <p>
          Whether you’re an emerging leader or a seasoned executive, 
          leadership.NET offers leadership resources, expert guidance, 
          free webinars, and structured opportunities to practice leadership in meaningful ways.
        </p>
        <h2>LeaderBridge</h2>
        <p>
        LeaderBridge is launching exclusively for early adopters who commit to a full year of leadership practice. To create a powerful network effect, we are gathering a critical number of subscribers before officially launching. Once we reach this number, all early adopters will be notified and will join the platform together, ensuring a strong, committed community from day one.
        </p>
        <a href='https://leaderbridge.com/assessmentRanking?data=ranking1' className="cta-btn">Explore Leadership</a>
      </div>
    </section>
  );
};

export default HeroSection;
