import React from "react";
import "./SnapshotsSection.scss";

const SnapshotsSection = () => {
  return (
    <div className="snapshots-section">
      <div className="container">
        <h2>Practice Leadership</h2>

        <div className="snapshots-grid">
          <div className="snapshot-item">
          <img src={require("../../images/snap/image.png")} alt="Actionable Insights Snapshot" />
          </div>

          <div className="snapshot-item">
          <img src={require("../../images/snap/image2.png")} alt="Actionable Insights Snapshot" />
          </div>

          <div className="snapshot-item">
          <img src={require("../../images/snap/image3.png")} alt="Actionable Insights Snapshot" />
          </div>
        </div>


      </div>
    </div>
  );
};

export default SnapshotsSection;


{/* <img src={require("../../images/snap/image2.png")} alt="Actionable Insights Snapshot" /> */}
