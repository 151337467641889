import React from "react";
import "./Journey.scss";

const Journey = () => {
  const handleRedirect = (url) => {
    window.location.href = url;
  };
  return (
    <div className="journey-container">
      <div className="content">
        <h1>Ready to begin your leadership journey?</h1>
        <button
          onClick={() =>
            handleRedirect(
              "https://leaderbridge.com/assessmentRanking?data=ranking1"
            )
          }
          className="start-button"
        >
        Complete the Free DOER-R Context Exercise(10 mins)
        </button>
      </div>
    </div>
  );
};

export default Journey;
