import React from "react";
import "./DoerRSection.scss";

const DoerRSection = () => {
  return (
    <div className="doer-r-section">
      <div className="container">
      <div className="doerr-heading">
      <h2>
        Introducing DOER-R<span className="copyright">&copy;</span> : The Essential Leadership Framework
      </h2>
    </div>
        <p className="intro-text">
          At <span className="highlight">leadership.NET</span>, we’ve developed
          a basic leadership framework called <strong>DOER-R<span className="small-copyright">&copy;</span></strong>.
          LeaderBridge brings this framework to life, guiding you through a
          structured process for tackling real-world leadership challenges.
        </p>

        <div className="framework-cards">
          <div className="framework-card">
            <i className="fas fa-check icon"></i>
            <h3>Resolve</h3>
            <p>Take action and solve the issue.</p>
          </div>

          <div className="framework-card">
            <i className="fas fa-search icon"></i>
            <h3>Define</h3>
            <p>Clarify the specific challenge you are facing and want to practice</p>
          </div>

          <div className="framework-card">
            <i className="fas fa-users icon"></i>
            <h3>Enlist</h3>
            <p>Collaborate and gain support from others.</p>
          </div>

          <div className="framework-card">
            <i className="fas fa-hand-holding icon"></i>
            <h3>Own</h3>
            <p>Take responsibility for the solution.</p>
          </div>

          <div className="framework-card">
            <i className="fas fa-sync-alt icon"></i>
            <h3>Reinforce</h3>
            <p>Reflect on and solidify your success.</p>
          </div>
        </div>

        <p className="conclusion-text">
         LeaderBridge applies this framework to encourage leaders to take action daily to build lasting habits with continuous feedback from peers.
        </p>
      </div>
    </div>
  );
};

export default DoerRSection;
