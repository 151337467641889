import React from "react";
import "./LatestFooter.scss";

const LatestFooter = () => {
  return (
    <footer className="latest-footer">
      <div className="container">
        <div className="footer-info">
          <p className="lead-text"> Real Leadership Practice.</p>
          <h3 className="coming-soon">iOS & Android Apps Coming Soon!</h3>
        </div>
        <div className="app-cards">
          <a href="https://play.google.com/store/apps/details?id=sutechs.leader_bridge&pli=1" target="_blank" rel="noopener noreferrer" className="app-card">
            <i className="fab fa-google-play"></i>
            <span>Google Play</span>
          </a>
          <a href="https://apps.apple.com/in/app/leaderbridge/id1555917945" target="_blank" rel="noopener noreferrer" className="app-card">
            <i className="fab fa-apple"></i>
            <span>App Store</span>
          </a>
        </div>
        <div className="social-links">
          {/* <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook-f"></i>
          </a> */}
          <a href="https://x.com/leadership_net" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="https://www.linkedin.com/company/-leaderbridge/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin-in"></i>
          </a>
          {/* <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a> */}
        </div>
      </div>
    </footer>
  );
};

export default LatestFooter;
